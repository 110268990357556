import * as React from "react";
import Layout from "../components/layout";

import { useState } from "react";

import { StaticImage } from "gatsby-plugin-image";

import at from "../images/at.svg";

const formatNumber = new Intl.NumberFormat("en-CA", {
  style: "currency",
  currency: "CAD",
}).format;

const emptyCart = {
  ab_laminated: {
    name: "Alberta: Laminated (1:750,000)",
    label: "Laminated",
    price: 79.99,
    quantity: 0,
  },
  ab_unlaminated: {
    name: "Alberta: Plain (1:750,000)",
    label: "Plain",
    price: 59.99,
    quantity: 0,
  },
  sk_laminated: {
    name: "Saskatchewan: Laminated (1:800,000)",
    label: "Laminated",
    edition: "2nd edition",
    price: 79.99,
    quantity: 0,
  },
  sk_unlaminated: {
    name: "Saskatchewan: Plain (1:800,000)",
    label: "Plain",
    edition: "1st edition",
    price: 59.99,
    quantity: 0,
  },
  pin: {
    name: "Bunnyhug #2 Enamel Pin",
    price: 0,
    quantity: 0
  }
};

const tax_rate = {
  AB: [
    {
      name: "GST",
      rate: 0.05
    }
  ],
  BC: [
    {
      name: "GST",
      rate: 0.05
    }
  ],
  SK: [
    {
      name: "GST",
      rate: 0.05
    },
    {
      name: "PST",
      rate: 0.06
    }
  ],
  MB: [
    {
      name: "GST",
      rate: 0.05
    }
  ],
  ON: [
    {
      name: "HST",
      rate: 0.13
    }
  ],
  QC: [
    {
      name: "GST",
      rate: 0.05
    }
  ],
  NB: [
    {
      name: "HST",
      rate: 0.15
    }
  ],
  PE: [
    {
      name: "HST",
      rate: 0.15
    }
  ],
  NS: [
    {
      name: "HST",
      rate: 0.15
    }
  ],
  NL: [
    {
      name: "HST",
      rate: 0.15
    }
  ],
  YT: [
    {
      name: "GST",
      rate: 0.05
    }
  ],
  NT: [
    {
      name: "GST",
      rate: 0.05
    }
  ],
  NU: [
    {
      name: "GST",
      rate: 0.05
    }
  ],
};

const shipping_tax_rate = {
  SK: [
    {
      name: "GST",
      rate: 0.05
    },
  ]
};

function ProductSelector({ id, product, onChange }) {
  return (
    <div className="valueSelector">
      <select
        className="quantity"
        type="number"
        id={id}
        name={id}
        value={product.quantity}
        onChange={onChange}
      >
        <option value="0">0</option>
        <option value="1">1</option>
        <option value="2">2</option>
        <option value="3">3</option>
        <option value="4">4</option>
        <option value="5">5</option>
        <option value="6">6</option>
      </select>
      <p>{product.label}</p>
      {product.edition && <p className={product.edition === "2nd edition" ? "editionTwo" : "edition"}>{product.edition}</p>}
      <p>{`${formatNumber(
        product.price
      )} each`}</p>
    </div>
  );
}

function ProductDisplay({ products, onChange, pinEligible, togglePin }) {

  return (
    <table>
      <thead>
        <tr>
          <th scope="col">Product</th>
          <th scope="col">Quantity</th>
          <th scope="col">Subtotal</th>
        </tr>
      </thead>

<tbody>
      <tr>
        <td className="product">
        <div className="prodContainer">
          <StaticImage className="prodimg" src="../images/tinysk.jpg" alt="" />
          <div className="mapdim mapheight">66"</div>
        </div>
        <div>
          <strong>Saskatchewan</strong>
        </div>
        <div>1:800,000</div>
        </td>
        <td>
          <ProductSelector
            id={"sk_unlaminated"}
            product={products.sk_unlaminated}
            onChange={onChange}
          />
          <ProductSelector
            id={"sk_laminated"}
            product={products.sk_laminated}
            onChange={onChange}
          />
        </td>
        <td>
          <span className="subtotal">
            {formatNumber(products.sk_laminated.price * products.sk_laminated.quantity + products.sk_unlaminated.price * products.sk_unlaminated.quantity)}
          </span>
        </td>
    </tr>

    <tr>
        <td className="product">
        <div className="prodContainer">
          <StaticImage className="prodimg alberta" src="../images/tinyab.jpg" alt="" />
          <div className="mapdim mapheight">68"</div>
        </div>
        <div>
          <strong>Alberta</strong>
        </div>
        <div>1:750,000</div>
        </td>
        <td>
          <ProductSelector
            id={"ab_unlaminated"}
            product={products.ab_unlaminated}
            onChange={onChange}
          />
          <ProductSelector
            id={"ab_laminated"}
            product={products.ab_laminated}
            onChange={onChange}
          />
        </td>
        <td>
          <span className="subtotal">
            {formatNumber(products.ab_laminated.price * products.ab_laminated.quantity + products.ab_unlaminated.price * products.ab_unlaminated.quantity)}
          </span>
        </td>
    </tr>

      <tr className="product">
        <td colSpan="1">
        <div className="prodContainer">
          <StaticImage className="prodimg pin" src="../images/pin2.png" alt="" />
          <div className="mapdim mapheight">1.5"</div>
        </div>
        <div>
          <strong>Enamel pin</strong>
        </div>
        <div>#2 Bunnyhug</div>
        </td>
        <td colSpan="3" className="checkBox">
          <label>
          <input type="checkbox" disabled={!pinEligible(products)} checked={products.pin.quantity > 0} name="pin" onClick={togglePin} />
            <div><strong>YES</strong>, by checking this box I confirm that I am ordering two or more maps and want a <strong>FREE</strong> limited edition enamel pin included in my shipment.</div>
          </label>
          </td>
      </tr>
      </tbody>
    </table>
  );
}

function CountryForm({ country, province, onCountryChange, onProvinceChange }) {
  return (
    <div className="country">
      <input type="radio" name="country" id="canada" value="canada" onClick={onCountryChange}/>
      <div className="canadaCountrySelector">
      <label id="canadaLabel" onClick={onCountryChange} htmlFor="canada" className={country === "canada" ? "option selected" : "option"}>
        <div>
        <div>
          <span role="img" aria-label="A maple leaf">
            🍁
          </span>
        </div>
        <div>Canada</div>
        </div>
      </label>
      <label style={{display: (country === "canada" ? "block" : "none")}}>
      <select id="provinceSelector" name="province" className={province ? "selected" : ""} onChange={onProvinceChange} value={province}>
          <option value="" style={{display: "none"}}>—</option>
          <option value="BC">British Columbia</option>
          <option value="AB">Alberta</option>
          <option value="SK">Saskatchewan</option>
          <option value="MB">Manitoba</option>
          <option value="ON">Ontario</option>
          <option value="QC">Quebec</option>
          <option value="NB">New Brunswick</option>
          <option value="PE">Prince Edward Island</option>
          <option value="NS">Nova Scotia</option>
          <option value="NL">Newfoundland and Labrador</option>
          <option value="YT">Yukon</option>
          <option value="NT">Northwest Territories</option>
          <option value="NU">Nunavut</option>
        </select>
      </label>
      </div>
      <input type="radio" name="country" id="us" value="us" onClick={onCountryChange} />
      <label htmlFor="us" className={country === "us" ? "option selected" : "option"}>
        <div>
          <span role="img" aria-label="A star">
            ⭐
          </span>
        </div>
        <div>United States</div>
      </label>
      <input type="radio" name="country" id="other" value="other" onClick={onCountryChange} />
      <label htmlFor="other" className={country === "other" ? "option selected" : "option"} id="other">
        <div>
          <span role="img" aria-label="A globe">
            🌍
          </span>
        </div>
        <div>World</div>
      </label>
    </div>
  );
}

export default function Index () {

  const [products, setProducts] = useState(emptyCart);
  const [country, setCountry] = useState(undefined);
  const [province, setProvince] = useState("");

  let errorMessage = "";

  function pinEligible(cart) {
    const validMaps = Object.entries(cart)
    .filter(
      ([id, product]) =>
        product.quantity > 0 && (id.startsWith("ab") || id.startsWith("sk"))
    )
    .reduce((total, [_, product]) => total + product.quantity, 0)

    return(validMaps > 1);
  }

  function handleQuantityChange(event) {
    const changedProduct = products[event.target.id];
    const newQuantity = event.target.value;
    const newEntry = { ...changedProduct, quantity: newQuantity };
    let newProducts = { ...products, [event.target.id]: newEntry };
    if (!pinEligible(newProducts) && products.pin.quantity > 0) {
      newProducts["pin"] = { ...products["pin"], quantity: 0 };
    }
    setProducts(newProducts);
  }

  function togglePin() {
      const newEntry = { ...products["pin"], quantity: products.pin.quantity === 0 ? 1 : 0 };
      setProducts({ ...products, "pin": newEntry });
  }

  function handleCountryChange(event) {
    if (event.currentTarget.id === "canadaLabel") {
      setCountry("canada");
    } else {
      setCountry(event.currentTarget.id);
    }
  }

  function handleProvinceChange(event) {
    setProvince(event.target.value);
  }

  const shouldCheckout = (() => {

    const countryOK = (country === "canada" && province !== "") || country === "us";

    const hasQuantity = p => p.quantity > 0;

    if (!Object.values(products).some(hasQuantity)) {
      errorMessage = "Select a product to continue.";
      return false;
    } else if (!countryOK) {
        if (country === "other") {
          errorMessage = "";
        }
        if (country === "canada" && !province) {
            errorMessage = "Select a province to continue."
        } else {
          errorMessage = "Select a shipping location to continue.";
        }
        return false;
    }

    if (!countryOK) {
      return false;
    }

    errorMessage = "";
    return true;

  })();

  function getSubtotal() {
    let subtotal = 0;
    for (const id in products) {
      const product = products[id];
      subtotal += product.quantity * product.price;
    }

    return subtotal;
  }

  function getShipping() {
    let onlyPins = Object.entries(products)
      .filter(([_, product]) => product.quantity > 0)
      .every(([id, _]) => id.startsWith("pin"));

    let shipping = onlyPins ? "envelope" : "largeTube";

    if (country === "other") {
      if (shipping === "envelope") {
        return 10;
      } else {
        return 40;
      }
    } else if (!country) {
      return 0;
    }  else {
      // Canada / US Shipping
      if (shipping === "envelope") {
        return 2;
      } else {
        return 20;
      }
    }
  }

  function getTax(subtotal, shipping) {
    if (country === "canada") {
      let productTaxRate = tax_rate[province]?.reduce(
        (a, b) => a + b.rate,
        0
      );
      let shippingTaxRate = shipping_tax_rate[province]?.reduce(
        (a, b) => a + b.rate,
        0
      ) || productTaxRate;
      return subtotal * (productTaxRate || 0) + shipping * (shippingTaxRate || 0);
    } else {
      return 0;
    }
  }

  function getTaxRow(productTotal, shipping) {
    let shipping_rates = shipping_tax_rate[province]
    if (!shipping_rates) {
      return tax_rate[province].map(tax => (
        <tr key={tax.name}>
          <th>{tax.name}</th>
          <td>{formatNumber((productTotal + shipping) * tax.rate)}</td>
        </tr>
      ));
    } else {
      return tax_rate[province].map(tax => {
        let subtotalTax = productTotal * tax.rate;
        let shippingTaxRate = shipping_rates.find(shipping_tax => shipping_tax.name === tax.name);
        let shippingTax = shipping * (shippingTaxRate?.rate || 0);
        return (
          <tr key={tax.name}>
            <th>{tax.name}</th>
            <td>{formatNumber(subtotalTax + shippingTax)}</td>
          </tr>
        );
      });
    }
  }

    let subtotal = getSubtotal();
    let shipping = getShipping();
    let tax = getTax(subtotal, shipping);
    
    let total = subtotal + tax + shipping;

    return (
      <Layout>
        <h1>Map Order Form</h1>
        <p>I specialize in reference maps. They ship rolled in a tube, not folded, and are printed on a glossy cardstock designed for sturdy wall display. My laminated maps are splash-resistant and pair well with erasable markers.</p>
        <form action={`${process.env.GATSBY_THIS_URL}/.netlify/functions/create-checkout-session`} method="post">
          <ProductDisplay
            products={products}
            onChange={handleQuantityChange}
            pinEligible={pinEligible}
            togglePin={togglePin}
          />
          <h3>Shipping Location</h3>
          <CountryForm
            country={country}
            province={province}
            onCountryChange={handleCountryChange}
            onProvinceChange={handleProvinceChange}
          />
        <div className="total">
            <table id="totalTable">
              <tbody>
              {country && <tr>
                <th>Shipping</th>
                <td>{formatNumber(shipping)}</td>
              </tr>}
              <tr>
                <th>Subtotal</th>
                <td>{formatNumber(subtotal + shipping)}</td>
              </tr>
              {country === "canada" &&
                tax_rate[province] &&
                getTaxRow(subtotal, shipping)
              }
              {country && <tr>
                <th>Total</th>
                <td>
                  {formatNumber(total)}{" "}
                  <span className="currency">
                    {country === "canada" ? "CAD" : "USD"}
                  </span>
                </td>
              </tr>}
              </tbody>
            </table>

          {Object.entries(products).some(
            ([id, product]) =>
              6 <= product.quantity &&
              (id.startsWith("ab") || id.startsWith("sk"))
          ) && (
            <p className="wholesale">
              Interested in a wholesale order? All maps are 40% off in
              quantities over 6. Contact me at alex
              <img src={at} alt=" at " className="at" />
              awmcphee.ca
            </p>
          )}
          {errorMessage && (
            <p className="error">{errorMessage}</p>
          )}
          <p className="total">
            <input
              type="submit"
              value="Checkout"
              id="checkout-button"
              disabled={!shouldCheckout}
              className={shouldCheckout ? "" : "disabled"}
            />
          </p>
          {country === "other" && (
            <p className="total">All products except Alberta can be shipped internationally, but for the time being I'll need to specially invoice your shipping rate: contact me at alex<img src={at} alt=" at " className="at" />awmcphee.ca. Expect starting rates around $10 USD for pins and $40 USD for Saskatchewan maps.</p>
          )}
        </div>
        </form>
      </Layout>
    );
}
